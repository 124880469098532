import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React, { VFC, useEffect, useState, useRef } from "react"
import { useLocation } from "@reach/router"
import { Transition } from "@headlessui/react"
import DrawerMenu from "./DrawerMenu"
import "../../styles/header.css"
import { getBgImage, getTextColor, Target } from "../../utils"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import useHeaderScroll from "../../hooks/ useHeaderScroll"

type Props = {
  logo: IGatsbyImageData
  path: Target
}

const Header: VFC<Props> = ({ logo, path }) => {
  const [isExpanded, toggleExpansion] = useState(false)
  const [currentPath, setCurrentPath] = useState("")
  const { isScrolled } = useHeaderScroll()
  const location = useLocation()
  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [])

  const toggleMenu = () => {
    toggleExpansion(!isExpanded)
  }

  const ref = useRef()
  useOnClickOutside(ref, () => toggleExpansion(false))

  const menuList = [
    { text: "ホーム", path: `/${path}/` },
    { text: "施設案内", path: `/${path}/about/` },
    { text: "自己評価公表", path: `/${path}/assessment/` },
    { text: "ご利用案内", path: `/${path}/usage/` },
    { text: "プログラム", path: `/${path}/program/` },
    { text: "よくあるご質問", path: `/${path}/faq/` },
    { text: "お問い合わせ", path: `/${path}/contact/` },
    { text: "企業TOP", path: `/` },
  ]
  return (
    <nav
      className={`sticky top-0 flex flex-wrap items-center justify-between px-5 py-3 bg-repeat-round z-10 ${
        isScrolled
          ? "bg-transparent md:bg-opacity-70 md:bg-white"
          : getBgImage(path)
      }`}
    >
      <div className="flex items-center flex-shrink-0 mr-6 text-black">
        <Link to={`/${path}/`}>
          <GatsbyImage
            image={logo}
            alt="noone logo"
            className="w-12 text-xl font-semibold tracking-tight"
          />
        </Link>
      </div>
      <div className="hidden space-x-6 md:flex">
        {menuList.map(item => {
          return (
            <Link
              key={item.path}
              to={item.path}
              className={`font-kiwiMaru ${
                item.path === currentPath
                  ? `${getTextColor(path)} font-bold`
                  : undefined
              }`}
            >
              {item.text}
            </Link>
          )
        })}
      </div>
      <button
        className="z-20 hamburger md:hidden no-outside"
        id="hamburger1"
        onClick={toggleMenu}
      >
        <span
          className={`line no-outside ${isExpanded ? "line1-active" : ""}`}
        ></span>
        <span
          className={`line no-outside ${isExpanded ? "line2-active" : ""}`}
        ></span>
        <span
          className={`line no-outside ${isExpanded ? "line3-active" : ""}`}
        ></span>
      </button>
      <Transition
        ref={ref}
        show={isExpanded}
        enter="transition ease-in-out duration-500 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-500 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        className="fixed top-0 z-10 w-3/4 h-screen px-12 py-6 bg-white shadow -right-5"
      >
        <DrawerMenu toggleMenu={toggleMenu} items={menuList} target={path} />
      </Transition>
    </nav>
  )
}

export default Header
