import React, { useEffect, useState } from "react"
import { getBgColor, Target } from "../../utils"

type Props = {
  target: Target
}

const ScrollToTopButton = ({ target }: Props) => {
  const [opacity, setOpacity] = useState("opacity-0")

  const scroll_to_top = () => {
    if (typeof window === "undefined" || !window.document) {
      return
    }
    window.scroll({ top: 0, behavior: "smooth" })
  }

  const scroll_event = () => {
    if (typeof window === "undefined" || !window.document) {
      return
    }

    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    )

    if (window.pageYOffset > 400) {
      setOpacity("opacity-1")
    } else if (window.pageYOffset < 400) {
      setOpacity("opacity-0")
    }
  }

  useEffect(() => {
    if (typeof window === "undefined" || !window.document) {
      return
    }
    //スクロール時のイベントを追加
    window.addEventListener("scroll", scroll_event)
  }, [])

  return (
    <div>
      <button
        id="scroll-to-top-btn"
        className={`fixed md:w-16 w-12 rounded-full bottom-3 right-2 transition duration-300 ${opacity}`}
        onClick={scroll_to_top}
      >
        <div
          className={`flex items-center w-12 h-12 rounded-full shadow-md ${getBgColor(
            target
          )}`}
        >
          <div className="w-4 h-4 m-auto mb-3 rotate-45 border-t-2 border-l-2 border-white"></div>
        </div>
      </button>
    </div>
  )
}

export default ScrollToTopButton
