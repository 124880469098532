import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { VFC } from "react"
import { getBgWaveSp, getBgWavePc, getBgImage, Target } from "../../utils"

type Props = {
  target: Target
  name: string
  subName?: string
  appNum: number
  address: string
  tel: string
  fax: string
  logo: IGatsbyImageData
}

const Footer: VFC<Props> = ({
  target,
  name,
  subName,
  appNum,
  address,
  tel,
  fax,
  logo,
}) => {
  const menu1 = [
    { text: "ホーム", path: `/${target}/` },
    { text: "施設案内", path: `/${target}/about/` },
    { text: "ご利用案内", path: `/${target}/usage/` },
  ]
  const menu2 = [
    { text: "プログラム", path: `/${target}/program/` },
    { text: "よくある質問", path: `/${target}/faq/` },
    { text: "お問い合わせ", path: `/${target}/contact/` },
  ]
  return (
    <footer>
      <div
        className={`h-4 mt-20 md:mt-32 bg-repeat-x md:h-8 ${getBgWaveSp(
          target
        )} ${getBgWavePc(target)}`}
      ></div>
      <div className={`relative  bg-repeat-round ${getBgImage(target)}`}>
        <div className="mx-auto max-w-[960px] pt-5 px-4">
          <div className="flex flex-col-reverse pt-8 pb-12 md:pb-20 md:flex-row md:justify-between">
            <div className="justify-center pb-8 md:flex md:pb-0">
              <div className="mb-5 text-center md:mb-0">
                <GatsbyImage
                  image={logo}
                  alt="logo"
                  className="flex-shrink-0"
                />
              </div>
              <div className="text-center md:text-left md:ml-20">
                <div className="font-kiwiMaru">{subName}</div>
                <div className="mb-4 text-2xl font-kiwiMaru">{name}</div>
                <div className="mb-2 whitespace-pre-wrap">指定申請番号：{appNum}</div>
                <div className="mb-2 whitespace-pre-wrap">{address}</div>
                <div>TEL: {tel}</div>
                <div>FAX: {fax}</div>
              </div>
            </div>
            <div className="flex justify-center flex-shrink-0 pb-8 space-x-10 font-kiwiMaru md:pb-0">
              <div>
                {menu1.map(item => {
                  return (
                    <Link to={item.path} className="block mb-4" key={item.text}>
                      {item.text}
                    </Link>
                  )
                })}
                <Link to="/" className="block mb-4">
                  企業TOP
                </Link>
              </div>
              <div>
                {menu2.map(item => {
                  return (
                    <Link to={item.path} className="block mb-4" key={item.text}>
                      {item.text}
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="absolute -translate-x-1/2 bottom-4 left-1/2">
            © {new Date().getFullYear()} NOONE Inc.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
