import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"

type Props = {
  items: {
    title: string
    description: string
    image: IGatsbyImageData
  }[]
}

const ActivityExample: React.VFC<Props> = ({ items }) => {
  return (
    <div className="mt-12 md:mt-16">
      {items.map((item, index) => {
        return (
          <div
            className={`flex flex-col-reverse md:flex-row gap-5 ${
              index % 2 === 0 && "md:flex-row-reverse"
            }`}
            key={item.title}
          >
            <div className="my-auto md:w-1/2">
              <div className="text-2xl text-center font-kiwiMaru">
                {item.title}
              </div>
              <div className="mt-5 mb-16 whitespace-pre-wrap md:mt-14 md:mb-0">
                {item.description}
              </div>
            </div>
            <div className="md:w-1/2">
              <GatsbyImage
                image={item.image}
                alt="activity example"
                className="rounded-3xl"
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ActivityExample
