import React from "react"
import HeadingM from "../shared/HeadingM"
import Schedule from "../shared/program/Schedule"

const program = () => {
  const items = [
    {
      day: "指定なし",
      contents:
        "固定されたプログラムはライフネス活動のみで、月毎にスケジュール調整。\n\nライフネス活動\n児発：(水)10：30～11：30\n放デイ：(月)16：00～17：00",
    },
    {
      day: "祝日",
      contents:
        "祝日はイベントとして活動しています。\n記念日に因んだ活動を取り入れています。",
    },
  ]
  return (
    <div>
      <HeadingM title="スケジュール" target="one" />
      <Schedule items={items} target="one" />
    </div>
  )
}

export default program
