import React from "react"
import { getUnderlineWave, getUnderlineWaveSp, Target } from "../../utils"

type Props = {
  title: string
  target: Target
}

const HeadingM: React.VFC<Props> = ({ title, target }) => {
  return (
    <div className="mt-12 md:mt-20">
      <div className="text-2xl text-center font-kiwiMaru md:text-3xl">
        {title}
      </div>
      <div
        className={`mx-auto mt-6 md:mt-8 md:w-36 w-[100px] h-5 bg-no-repeat ${getUnderlineWaveSp(
          target
        )} ${getUnderlineWave(target)}`}
      ></div>
    </div>
  )
}

export default HeadingM
