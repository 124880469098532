import React, { VFC } from "react"
import { getBgImage, Target } from "../../../utils"

type Props = {
  items: {
    day: string
    contents: string
  }[]
  target: Target
}

const Schedule: VFC<Props> = ({ items, target }) => {
  return (
    <div className="mt-12 md:mt-16">
      <div className="space-y-10 md:space-x-5 md:flex md:space-y-0">
        <table className="w-full border">
          <tbody>
            <tr className={`${getBgImage(target)}`}>
              <th className="p-5">開催日</th>
              <th>内容</th>
            </tr>
            {items.map((item, index) => {
              return (
                <tr key={`${item.day}-${index}`}>
                  <td className="w-1/4 text-center border">{item.day}</td>
                  <td className="px-4 py-5 text-center whitespace-pre-wrap border">
                    {item.contents}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="mt-7">
        ​※お迎え・お送りの時間につきましては曜日や送迎の関係上異なりますのでご相談下さい。
      </div>
    </div>
  )
}

export default Schedule
