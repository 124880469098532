import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import { Target } from "../../utils"
import FooterBase from "../shared/Footer"

const query = graphql`
  query {
    logo: file(relativePath: { eq: "one/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100)
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(query)
  const items = {
    name: "one",
    subName: "児童発達支援・放課後等デイサービス",
    target: "one" as Target,
    address: "〒378-0053 群馬県沼田市高橋場4586",
    appNum: 1050600137,
    tel: "0278-25-4900",
    fax: "0278-25-4901",
    logo: getImage(data.logo),
  }

  return <FooterBase {...items} />
}

export default Footer
