import React from "react"
import { getBgImage, getBgWavePc, getBgWaveSp, Target } from "../../utils"

type Props = {
  title: string
  target: Target
}

const HeadingL: React.VFC<Props> = ({ title, target }) => {
  return (
    <div
      style={{
        margin: `0 calc(50% - 50vw)`,
      }}
    >
      <div className={`pb-6 pt-3 md:pt-5 md:pb-10 ${getBgImage(target)}`}>
        <div className="text-2xl text-center font-kiwiMaru md:text-3xl">
          {title}
        </div>
      </div>
      <div
        className={`h-4 rotate-180 bg-repeat-x md:h-8 ${getBgWaveSp(
          target
        )} ${getBgWavePc(target)}`}
      ></div>
    </div>
  )
}

export default HeadingL
