export const PAGE = {
  title: {
    program: "プログラム",
    about: "施設案内",
    assessment: "自己評価公表",
    usage: "ご利用案内",
    faq: "よくある質問",
    contact: "お問い合わせ",
  },
} as const;
