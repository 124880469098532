import { useEffect, useState } from "react"

const useHeaderScroll = (scrollLength?: number) => {
  const [isScrolled, setIsScrolled] = useState(false)

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > (scrollLength || 100)) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar)

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar)
      }
    }
  })

  return { isScrolled }
}

export default useHeaderScroll
