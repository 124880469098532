import React, { useState, VFC } from "react"
import { Link } from "gatsby"
import { useEffect } from "react"
import { useLocation } from "@reach/router"
import { getTextColor, Target } from "../../utils"

type Props = {
  items: {
    text: string
    path: string
  }[]
  toggleMenu: () => void
  target: Target
}

const DrawerMenu: VFC<Props> = ({ toggleMenu, items, target }) => {
  const [currentPath, setCurrentPath] = useState("")
  const location = useLocation()
  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [])

  return (
    <div className="text-center">
      <div className="mt-20 space-y-8 text-lg text-brown font-kiwiMaru">
        {items.map(item => {
          return (
            <div key={item.text}>
              <Link
                to={item.path}
                className={
                  item.path === currentPath
                    ? `${getTextColor(target)} font-bold`
                    : undefined
                }
                onClick={toggleMenu}
              >
                {item.text}
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DrawerMenu
